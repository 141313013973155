export class Constants {

  public readonly _AI = "AI";
  public readonly _LC = "LC";
  public readonly _FM = "FM";
  public readonly _WF = "WF";
  public readonly _GWY = "GWY";

  public readonly UPLOAD_BUTTONIMAGE = "image";
  public readonly TELEPHONY = "Telephony";
  public readonly VIDEO_CALLING = "LC - Video Calling";
  public readonly LEON = "LEON";
  public readonly SENTINEL = "SENTINEL";
  public readonly SYSTEM = "System";
  public readonly TITLE_DC_LEAON = "DigitalChameleon Leaon";
  public readonly TRANSFER_NOT_PERMITTED = "Transfer to this agent is not permitted";
  public readonly MSG_GUEST_CONNECTED_WITH_AGENT = "Guest connected with agent";
  public readonly MSG_ON_CRUISE_CONTROL_AND_INTERVENE_REQ = "Please make intervene request to intervene the Guest at any point";
  public readonly MSG_ON_CRUISE_CONTROL_AND_INTERVENE_REQ_WAIT = "Please wait until Intervention Request is accepted";
  public readonly MSG_ON_CRUISE_CONTROL_AND_INTERVENE_ACCEPTANCE = "You can now intervene the Guest";
  public readonly MSG_ON_PIN_RESPONSE_SUCCESS = "Responses pinned successfully";
  public readonly TEMPLATE_CONVERTED_NOT_CURRENTLY_TEXT = "Obsolete template";
  public readonly TEMPLATE_CONVERTED_CURRENTLY_TEXT = "Updated Template";
  public readonly TEMPLATE_NOT_CONVERTED_TEXT = "Not yet converted to Template";
  public readonly READ_DENIED_MSG = "You do no have the permissions to read this. Contact your administrator";
  public readonly CREATE_DENIED_MSG = "You do no have the permissions to create this. Contact your administrator";
  public readonly UPDATE_DENIED_MSG = "You do no have the permissions to update this. Contact your administrator";
  public readonly DELETE_DENIED_MSG = "You do no have the permissions to delete this. Contact your administrator";
  public readonly MSG_HEARTBEAT = "heartbeat";
  public readonly MSG_HELLO = "helloMsg";
  public readonly MSG_GOOD_DAY = "goodDayMsg";
  public readonly MSG_GET_NEW_TOKEN = "Get new token";
  public readonly MSG_SESSION_ABOUT_TO_EXPIRE_ALERT = "Session is about to expire. Would you like to extend your session?";

  public readonly NEXT_ACTION_CONVERSATION_STACK = "conversationStack";
  public readonly NEXT_ACTION_FORM_STACK = "formStack";
  public readonly NEXT_ACTION_TRIGGER = "trigger";
  public readonly NEXT_ACTION_INTENT = "intent";
  public readonly NEXT_ACTION_STACK = "stack";
  public readonly NEXT_ACTION_DELETE_REQUESTED = "DELETE_REQUESTED";
  public readonly NEXT_ACTION_DELETED = "DELETED";
  public readonly NEXT_ACTION_SUBMITTED = "SUBMITTED";
  public readonly NEXT_ACTION_APPROVED = "APPROVED";
  public readonly NEXT_ACTION_CONVERSATION = "Conversation";
  public readonly NEXT_ACTION_FORM = "Form";
  public readonly ID_TELEPHONY = "telephony";
  public readonly ID_VIDEOCALL = "videoCall";
  public readonly DISPLAY_BLOCK = "block";
  public readonly DISPLAY_NONE = "none";
  public readonly URL = "url";
  public readonly DAYS = "days";
  public readonly MONTH = "month";
  public readonly TRUE = "true";
  public readonly FALSE = "false";
  public readonly MESSAGE = "message";
  public readonly OKTA_IFRAME = "oktaIframe";
  public readonly CONTAINER_DIV = "containerDiv";
  public readonly TEXT_CONTENT = "textContent";
  public readonly MAIN_CONTENT = "main-content";
  public readonly SIDEBAR_SHIFT = "sidebar_shift";
  public readonly CHAT_SHIFT = "chat_shift";
  public readonly SECTION_SHIFT = "section-shift";
  public readonly INITIAL_NAVIGATION = "/";
  public readonly NAVIGATE_TO_RTMC = "/rtmc";
  public readonly ROUTER_URL_LIVECHAT = "/livechat";
  public readonly UPDATE_JOURNEY = "Update Journey";
  public readonly RESPONSE_MANAGEMENT = "Response Management";
  public readonly BB_BUILDER = "B&B Builder";
  public readonly CREATE_TRIGGER = "Create Trigger";
  public readonly CREATE_API = "Create API";
  public readonly NLP_ENGINE_MANAGEMENT = "NLP Engine Management";
  public readonly RESPONSES = "Responses";
  public readonly BRAND_AND_BEHAVIOURS = "Brands & Behaviours";
  public readonly POP_STATE = "popstate";

  public readonly MASTER = "MASTER";
  public readonly CLIENT = "CLIENT";
  public readonly CHAMELEON = "Chameleon";
  public readonly UNDEFINED_DATA = "-";
  public readonly SUCCESS = "success";
  public readonly WARNING = "warning";
  public readonly QUESTION = "question";
  public readonly ERROR = "error";
  public readonly ABOUT_BLANK = "about:blank";
  public readonly CMS_ACCESS_TOKEN = "cmsAccessToken";
  public readonly CMS_ID_TOKEN = "cmsIdToken";
  // kuc = KNOWN USER CAMIE
  public readonly KUC = "kuc";

  public readonly CREATE = "CREATE";
  public readonly UPDATE = "UPDATE";
  public readonly USER_ROLE_PREFIX = "ROLE";
  public readonly SENTINEL_ROLE_PREFIX = "ROLE_PL";

  public readonly ROLE_PLATFORM_ADMIN = "ROLE_PLATFORM_ADMIN";
  public readonly _RTMC_ADMIN = "_RTMC_ADMIN";
  public readonly _RTMC_READ = "_RTMC_READ";
  public readonly _RESP_ADMIN = "_RESP_ADMIN";
  public readonly _RESP_CREATE = "_RESP_CREATE";
  public readonly _RESP_DELETE = "_RESP_DELETE";
  public readonly _RESP_READ = "_RESP_READ";
  public readonly _RESP_WRITE = "_RESP_WRITE";
  public readonly _BM_ADMIN = "_BM_ADMIN";
  public readonly _BM_CREATE = "_BM_CREATE";
  public readonly _BM_DELETE = "_BM_DELETE";
  public readonly _BM_READ = "_BM_READ";
  public readonly _BM_WRITE = "_BM_WRITE";
  public readonly _SESSION_ADMIN = "_SESSION_ADMIN";
  public readonly _SESSION_READ = "_SESSION_READ";
  public readonly _CLIENT_READ = "_CLIENT_READ";
  public readonly _CLIENT_CREATE = "_CLIENT_CREATE";
  public readonly _CLIENT_WRITE = "_CLIENT_WRITE";
  public readonly _CLIENT_DELETE = "_CLIENT_DELETE";
  public readonly _CLIENT_ADMIN = "_CLIENT_ADMIN";
  public readonly _UM_ADMIN = "_UM_ADMIN";
  public readonly _UM_CREATE = "_UM_CREATE";
  public readonly _UM_DELETE = "_UM_DELETE";
  public readonly _UM_READ = "_UM_READ";
  public readonly _UM_WRITE = "_UM_WRITE";
  public readonly _LC_CHAT_READ = "_LC_CHAT_READ";
  public readonly _LC_TELE_READ = "_LC_TELE_READ";
  public readonly _LC_VC_READ = "_LC_VC_READ";
  public readonly _INTENT_ADMIN = "_INTENT_ADMIN";
  public readonly _INTENT_CREATE = "_INTENT_CREATE";
  public readonly _INTENT_DELETE = "_INTENT_DELETE";
  public readonly _INTENT_READ = "_INTENT_READ";
  public readonly _INTENT_WRITE = "_INTENT_WRITE";
  public readonly _NLP_ADMIN = "_NLP_ADMIN";
  public readonly _NLP_CREATE = "_NLP_CREATE";
  public readonly _NLP_DELETE = "_NLP_DELETE";
  public readonly _NLP_READ = "_NLP_READ";
  public readonly _NLP_WRITE = "_NLP_WRITE";
  public readonly _JB_ADMIN = "_JB_ADMIN";
  public readonly _JB_CREATE = "_JB_CREATE";
  public readonly _JB_DELETE = "_JB_DELETE";
  public readonly _JB_READ = "_JB_READ";
  public readonly _JB_WRITE = "_JB_WRITE";
  public readonly _ED_ADMIN = "_ED_ADMIN";
  public readonly _ED_CREATE = "_ED_CREATE";
  public readonly _ED_DELETE = "_ED_DELETE";
  public readonly _ED_READ = "_ED_READ";
  public readonly _ED_WRITE = "_ED_WRITE";
  public readonly _ANALYTICS_ADMIN = "_ANALYTICS_ADMIN";
  public readonly _ANALYTICS_CREATE = "_ANALYTICS_CREATE";
  public readonly _ANALYTICS_DELETE = "_ANALYTICS_DELETE";
  public readonly _ANALYTICS_READ = "_ANALYTICS_READ";
  public readonly _ANALYTICS_WRITE = "_ANALYTICS_WRITE";
  public readonly _TRIGGER_ADMIN = "_TRIGGER_ADMIN";
  public readonly _TRIGGER_CREATE = "_TRIGGER_CREATE";
  public readonly _TRIGGER_DELETE = "_TRIGGER_DELETE";
  public readonly _TRIGGER_READ = "_TRIGGER_READ";
  public readonly _TRIGGER_WRITE = "_TRIGGER_WRITE";
  public readonly _AUDIT_READ = "_AUDIT_READ";
  public readonly _API_DEFINITION_READ = "_API_DEFINITION_READ";
  public readonly _API_DEFINITION_WRITE = "_API_DEFINITION_WRITE";
  public readonly _API_DEFINITION_ADMIN = "_API_DEFINITION_ADMIN";
  public readonly _ML_ADMIN = "_ML_ADMIN";
  public readonly _ML_READ = "_ML_READ";
  public readonly _ML_WRITE = "_ML_WRITE";
  public readonly _ML_CREATE = "_ML_CREATE";
  public readonly _ML_DELETE = "_ML_DELETE";
  public readonly _API_HEADER_READ = "_API_HEADER_READ";
  public readonly _API_HEADER_WRITE = "_API_HEADER_WRITE";
  public readonly _API_HEADER_ADMIN = "_API_HEADER_ADMIN";
  public readonly _ASSET_ADMIN = "_ASSET_ADMIN";
  public readonly _ASSET_CREATE = "_ASSET_CREATE";
  public readonly _ASSET_DELETE = "_ASSET_DELETE";
  public readonly _ASSET_READ = "_ASSET_READ";
  public readonly _ASSET_WRITE = "_ASSET_WRITE";
  public readonly _INTERNAL_SCHEDULER = "_INTERNAL_SCHEDULER";
  public readonly _POLICY_CREATE = "_POLICY_CREATE";
  public readonly _POLICY_DELETE = "_POLICY_DELETE";
  public readonly _POLICY_READ = "_POLICY_READ";
  public readonly _POLICY_WRITE = "_POLICY_WRITE";
  public readonly _DC_DRIVE_READ = "_DC_DRIVE_READ";
  public readonly _DC_DRIVE_WRITE = "_DC_DRIVE_WRITE";
  public readonly _DC_DRIVE_CREATE = "_DC_DRIVE_CREATE";
  public readonly _DC_DRIVE_DELETE = "_DC_DRIVE_DELETE";
  public readonly _DC_DRIVE_ADMIN = "_DC_DRIVE_ADMIN";

  public readonly INFO = "info";
  public readonly READ = "READ";
  public readonly ADMIN = "Admin";
  public readonly RESPONSE = "response";
  public readonly CHANNEL_MESSAGE = "channelMsg";
  public readonly FULL_CLIENT_NAME = "fullClientName";

  public readonly NEW = "new";
  public readonly PUBLISHED = "published";
  public readonly SUBMITTED = "submitted";
  public readonly APPROVED = "approved";
  public readonly ACTIVE = "active";
  public readonly INACTIVE = "inactive";
  public readonly REJECTED = "rejected";
  public readonly SUBMIT_STATUS = "submit-status";
  public readonly APPROVE_STATUS = "approve-status";
  public readonly ACTIVE_STATUS = "active-status";
  public readonly INACTIVE_STATUS = "inactive-status";
  public readonly REJECT_STATUS = "reject-status";
  public readonly DRAFT_STATUS = "draft-status";
  public readonly NO_STATUS = "no-status";
  public readonly DISABLED = "disabled";
  public readonly DELETE_REQUESTED = "delete_requested";
  public readonly BEHAVIOUR_DISABLED = "behaviour_disabled";
  public readonly BEHAVIOUR_DELETE_REQUESTED = "behaviour_delete_requested";
  public readonly BEHAVIOUR_ENABLED_REQUESTED = "behaviour_enabled_requested";
  public readonly BEHAVIOUR_DISABLED_REQUESTED = "behaviour_disabled_requested";

  public readonly FILE_CATEGORY_TRG = "trg";
  public readonly FILE_CATEGORY_BHV = "bhv";
  public readonly FILE_CATEGORY_JRN = "jrn";
  public readonly FILE_CATEGORY_ASSET = "asset";

  public readonly DATE_FORMAT = "DD/MM/YYYY";
  public readonly INITIAL_DATE = "01/01/1970";
  public readonly DISPLAY_DATE_FORMAT = "MMM DD, YYYY hh:mm:ss A";

  public readonly ACCEPTABLE_DOCUMENT_TYPES = [
    ".doc",
    ".docx",
    ".pptx",
    ".xlsx",
    ".ppt",
    ".xls",
    ".pdf",
    ".csv",
    ".txt"
  ];
  public readonly ACCEPTABLE_VIDEO_TYPES = [
    ".webm",
    ".mp4",
    ".mov",
    ".mpeg"
  ];
  public readonly ACCEPTABLE_IMAGE_TYPES = [
    ".png",
    ".gif",
    ".jpg",
    ".jpeg",
    ".svg"
  ];
  public readonly MEETING_TIME = [
    { name: "12:00 AM", value: "00:00" },
    { name: "1:00 AM", value: "01:00" },
    { name: "2:00 AM", value: "02:00" },
    { name: "3:00 AM", value: "03:00" },
    { name: "4:00 AM", value: "04:00" },
    { name: "5:00 AM", value: "05:00" },
    { name: "6:00 AM", value: "06:00" },
    { name: "7:00 AM", value: "07:00" },
    { name: "8:00 AM", value: "08:00" },
    { name: "9:00 AM", value: "09:00" },
    { name: "10:00 AM", value: "10:00" },
    { name: "11:00 AM", value: "11:00" },
    { name: "12:00 PM", value: "12:00" },
    { name: "1:00 PM", value: "13:00" },
    { name: "2:00 PM", value: "14:00" },
    { name: "3:00 PM", value: "15:00" },
    { name: "4:00 PM", value: "16:00" },
    { name: "5:00 PM", value: "17:00" },
    { name: "6:00 PM", value: "18:00" },
    { name: "7:00 PM", value: "19:00" },
    { name: "8:00 PM", value: "20:00" },
    { name: "9:00 PM", value: "21:00" },
    { name: "10:00 PM", value: "22:00" },
    { name: "11:00 PM", value: "23:00" }
  ];
  public readonly CALENDAR_TYPE_LIST = [
    { name: "None", value: "NONE" },
    { name: "Google", value: "GOOGLE" },
    { name: "Outlook", value: "OUTLOOK" },
  ];

  public readonly DOCUMENT = "document";
  public readonly IMAGE = "image";
  public readonly VIDEO = "video";
  public readonly IMAGE_EXTENSIONS = ["jpg", "gif", "png", "jpeg", "svg"];
  public readonly VIDEO_EXTENSIONS = ["webm", "mp4", "mov", "mpeg"];
  public readonly DOCUMENT_EXTENSION = ["doc", "docx", "pptx", "xlsx", "ppt", "xls", "pdf", "csv", "txt"];

  public readonly DELETE_CONFIRM_BTN_COLOR = "#029ca4";
  public readonly DELETE_CANCEL_BTN_COLOR = "#999";

  /* Upload File Parameters */
  public readonly UPLOADED_FILE = "uploadedFile";
  public readonly FILE_NAME = "fileName";
  public readonly CATEGORY = "category";
  public readonly CLIENT_ID = "clientId";

  public readonly TWILIO_PASSCODE_URL = "https://video-app-3435-2983-dev.twil.io?passcode=86483934352983";
  public readonly VIDEO_CALL_URL_DIV = `<div><a target="_blank" href=${this.TWILIO_PASSCODE_URL}>${this.TWILIO_PASSCODE_URL}</a> <br> Room Name: DCChatroom</div>`;

  /* Auth service const url  */

  public readonly CMS_LOGIN_CONFIG_URL = "/clientmanagement/getLoginConfigNOU";
  public readonly API_GATEWAY_URL = "/clientmanagement/getClientApiGatewayUrlANON";
  public readonly AUTHENTICATE_USER_URL = "/clientmanagement/authenticateUserANON";
  public readonly VERIFY_ACCESS_TOKEN_URL = "/clientmanagement/verifyAccessToken";
  public readonly VERIFY_OTP = "/clientmanagement/verifyOTPAnon";
  public readonly RESET_PASSWORD_URL = "/clientmanagement/resetPasswordANON";
  public readonly COMPLETE_FORCED_RESET_PASS_URL = "/clientmanagement/completeForcedResetPasswordANON";
  public readonly INIT_FORGOT_PASS_URL = "/clientmanagement/initiateForgotPasswordANON";
  public readonly COMPLETE_FORGOT_PASS_URL = "/clientmanagement/completeForgotPasswordANON";
  public readonly CONFIRM_USER_URL = "/clientmanagement/confirmUserNameANON";
  public readonly HOST_URL = "/clientmanagement/getClientByHostUrl";
  public readonly LOGOUT_URL = "/clientmanagement/getLogoutUrl";
  public readonly STORE_GOOGLE_CAL_TOKEN = "/clientmanagement/storeGoogleCalAuthToken";
  public readonly STORE_MICROSOFT_CAL_TOKEN = "/clientmanagement/storeMSAuthTokens";
  public readonly STORE_ZOHO_TOKEN = "/clientmanagement/storeZohoAuthToken";
  public readonly RESEND_OTP = "/clientmanagement/resendOtpANON";

  public readonly SENTINEL_LOGIN_CONFIG_URL = "/chameleon-sentinel/getLoginConfigNOU";
  public readonly SENTINEL_AUTHENTICATE_USER_URL = "/chameleon-sentinel/authenticateUserANON";
  public readonly SENTINEL_RESET_PASSWORD_URL = "/chameleon-sentinel/resetPasswordANON";
  public readonly SENTINEL_CONFIRM_USER_URL = "/chameleon-sentinel/verifyConfirmationCodeANON";
  public readonly SENTINEL_INIT_FORGOT_PASS_URL = "/chameleon-sentinel/sentinelInitiateForgotPasswordANON";
  public readonly SENTINEL_COMPLETE_FORGOT_PASS_URL = "/chameleon-sentinel/sentinelCompleteForgotPasswordANON";
  public readonly SENTINEL_COMPLETE_FORCED_RESET_PASS_URL = "/chameleon-sentinel/sentinelCompleteForcedResetPasswordANON";
  public readonly SENTINEL_VERIFY_MFA_CODE_URL = "/chameleon-sentinel/verifyMFACodeANON";
  public readonly SENTINEL_LOGIN_URL = "/chameleon-sentinel/sentinelLogin";
  public readonly SENTINEL_VERIFY_ACCESS_TOKEN_URL = "/chameleon-sentinel/verifyAccessTokenSecured";
  public readonly SENTINEL_LOGIN_NEW_URL = "/chameleon-sentinel/sentinelLoginWithNewPass";
  public readonly SENTINEL_RESEND_OTP = "/chameleon-sentinel/resendOtpANON";

  public readonly HELP_IMG_URL = "./assets/img/CCX-Logo-Black.png";
  public readonly HELP_CAMIE_URL = "https://help.camie.au";
  public readonly HELP_SENTINEL_URL = "https://internal.camie.au";

  public readonly ENTER_KEY_CODE = 13;
  public readonly CLIENT_SEARCH_CHAR_LENGTH = 1;
  public readonly POLICY_LIST_PAGE_SIZE = 1000;
  public readonly PROMPT_DEF_HEIGHT = 300;
  public readonly CLIENT_SEARCH_IGNORE_KEY_CODES = [9, 13, 16, 17, 18, 20, 33, 34, 35, 36, 37, 38, 39, 40];

  public readonly SAVE = "save";
  public readonly CANCEL = "cancel";
  public readonly SUBMIT = "submit";
  public readonly SELECT_ALL = "selectAll";
  public readonly CHATBOT = "chatbot";
  public readonly LOADED = "loaded";
  public readonly IFRAME = "iframe";
  public readonly CHAT_WINDOW = "chatwindow";
  public readonly EXPAND = "expand";
  public readonly PAGE_URL = "pageURL";
  public readonly CLS_CHAT_WINDOW = "chat-window";
  public readonly CLS_CHAT_BTN = "chat-btn";
  public readonly CLS_CHAT_EXPAND = "chat-expand";
  public readonly DC_DRIVE_IFRAME = "dc-drive-iframe";
  public readonly ID = "id";
  public readonly NAME = "name";
  public readonly UI_POLICIES = "uiPolicies";
  public readonly SERVER_POLICIES = "serverPolicies";
  public readonly CK_EDITOR_KEY_EVENT = "key";
  public readonly IMPORTANT = "important";
  public readonly HEIGHT = "height";
  public readonly POLICY_TYPE_VIEW = "VIEW";
  public readonly POLICY_TYPE_TRIGGER = "TRIGGER";

  public readonly FILE_HTML = "html";
  public readonly FILE_XML = "xml";
  public readonly FILE_TEXT = "text";

  public readonly TEMPLATE_NOT_CONVERTED = "template-not-converted";
  public readonly TEMPLATE_CONVERTED_NOT_CURRENTLY = "template-converted-not-currently";
  public readonly TEMPLATE_CONVERTED_CURRENTLY = "template-converted-currently";

  public readonly NUMBER_CHAR_REGEX = /[0-9]/;
  public readonly UPPER_CASE_CHAR_REGEX = /[A-Z]/g;
  public readonly UNDERSCORE_LOWER_CASE_CHAR_REGEX = /_([a-z])/g;
  public readonly PHONE_NUM_PATTERN_REGEX = /^(\+?[1-9][0-9]{0,2})([\s-]?[0-9]{1,4}){1,}$/;

  public readonly NOT_UPLOADED = "NOT_UPLOADED";
  public readonly CALENDAR_TYPES = "calendarTypes";
  public readonly TIMEZONE_VALUE = "timezoneValue";
  public readonly CLIENT_DEPT_ID_LIST = "clientDeptIdList";
  public readonly ACTIVE_IB_VIEW = "clientDeptIdList";

  public readonly FIVE_MINS_IN_MILLIS = 300000;
}

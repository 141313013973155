import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "@app/layout/pages/layout/layout.component";
import { AuthGuard } from "@app-core/guards/auth/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        data: { title: "Home" },
        loadChildren: () =>
          import("../home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "rtmc",
        data: { title: "Real-time Dashboard" },
        loadChildren: () =>
          import("../rtmc/rtmc.module").then((m) => m.RtmcModule),
      },
      {
        path: "sessionManagement",
        data: { title: "Sessions" },
        loadChildren: () =>
          import("../session-management/session-management.module").then(
            (m) => m.SessionManagementModule
          ),
      },
      {
        path: "journeyBuilder",
        data: { title: "Workflows" },
        loadChildren: () => import("../journey-builder/journey-builder.module").then((m) => m.JourneyBuilderModule),
      },
      {
        path: "conversationMgmt",
        data: { title: "Conversation Management" },
        loadChildren: () =>
          import(
            "../conversation-management/conversation-management.module"
          ).then((m) => m.ConversationManagementModule),
      },
      {
        path: "livechat",
        data: { title: "Live Chats" },
        loadChildren: () =>
          import("../live-chat/live-chat.module").then((m) => m.LiveChatModule),
      },
      {
        path: "telephony",
        data: { title: "Telephony" },
        loadChildren: () =>
          import("../telephony/telephony.module").then((m) => m.TelephonyModule),
      },
      {
        path: "userManagement",
        data: { title: "Users" },
        loadChildren: () =>
          import("../user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: "entity",
        data: { title: "Entities" },
        loadChildren: () =>
          import("../entity/entity.module").then((m) => m.EntityModule),
      },
      {
        path: "clientManagement",
        data: { title: "Clients" },
        loadChildren: () =>
          import("../client-management/client.module").then(
            (m) => m.ClientModule
          ),
      },
      {
        path: "analytics",
        data: { title: "Analytics" },
        loadChildren: () =>
          import("../analytics/analytics.module").then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: "audit",
        data: { title: "Audit" },
        loadChildren: () =>
          import("../audit/audit.module").then(
            (m) => m.AuditModule
          ),
      },
      {
        path: "triggers",
        data: { title: "Triggers" },
        loadChildren: () =>
          import("../trigger/trigger.module").then(
            (m) => m.TriggerModule
          ),
      },
      {
        path: "apiManagement",
        data: { title: "API Definition" },
        loadChildren: () =>
          import("../api-management/api-management.module").then(
            (m) => m.ApiManagementModule
          ),
      },
      {
        path: "machineLearning",
        data: { title: "Machine Learning" },
        loadChildren: () => import("../machine-learning/machine-learning.module").then((m) => m.MachineLearningModule),
      },
      {
        path: "assets",
        data: { title: "Asset Hub" },
        loadChildren: () => import("../asset/asset.module").then((m) => m.AssetModule),
      },
      {
        path: "policies",
        data: { title: "Policies" },
        loadChildren: () => import("../policy-management/policy.module").then((m) => m.PolicyModule),
      },
      {
        path: "dashboard",
        data: { title: "Dashboard" },
        loadChildren: () => import("../dc-drive/dc-drive.module").then((m) => m.DcDriveModule),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule { }
